<style lang="less" scoped>
  .table__con {
    position: relative;
    .table--add {
      position: absolute;
      right: 0;
      top: 2px;
      z-index: 999;
    }
  }
</style>
<template>
  <el-dialog
    :visible.sync="currentShowFlag"
    append-to-body
    fullscreen
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @open="handleDialogOpen"
    @closed="handleDialogClosed"
  >
    <div
      slot="title"
      class="detail-dialog__header"
    >
      {{ $t('system_role.detailDialog') }}
    </div>
    <div>
      <simple-form
        ref="form"
        v-model="formModel"
        label-width="80px"
        :form-field="formField"
        :grid="{xs: 24, sm: 12, md: 8}"
        :view-flag="viewFlag"
      />
      <div class="table__con">
        <el-button
          v-if="!viewFlag && tabValue === 'second'"
          type="primary"
          class="table--add"
          @click="handleDatascopeAddClick"
        >
          {{ $t('operation.create') }}
        </el-button>
        <el-tabs v-model="tabValue">
          <el-tab-pane
            :label="$t('system_role.tabTitle')"
            name="first"
            :style="{height: containerHeight, overflow: 'auto'}"
          >
            <el-tree
              ref="permissionTree"
              node-key="id"
              :data="menuTreeData"
              :props="{ children: 'children', label: menuTranslate }"
              show-checkbox
            />
          </el-tab-pane>
          <el-tab-pane
            :label="$t('system_role.datascopeTabTitle')"
            name="second"
            :style="{height: containerHeight}"
          >
            <simple-table
              :table-column="tableColumn"
              :table-data="tableData"
              :pageable="false"
            >
              <template
                slot="controllerId"
                slot-scope="scope"
              >
                <form-component
                  v-model="scope.row"
                  :view-flag="tableColumnSlot.viewFlag"
                  :form-item="tableColumnSlot.controllerId"
                />
              </template>
              <template
                slot="tableId"
                slot-scope="scope"
              >
                <form-component
                  v-model="scope.row"
                  :view-flag="tableColumnSlot.viewFlag"
                  :form-item="tableColumnSlot.tableId"
                />
              </template>
              <template
                slot="columnId"
                slot-scope="scope"
              >
                <form-component
                  v-model="scope.row"
                  :view-flag="tableColumnSlot.viewFlag"
                  :form-item="tableColumnSlot.columnId"
                />
              </template>
              <template
                slot="attrInUser"
                slot-scope="scope"
              >
                <form-component
                  v-model="scope.row"
                  :view-flag="tableColumnSlot.viewFlag"
                  :form-item="tableColumnSlot.attrInUser"
                />
              </template>
              <template
                slot="methodId"
                slot-scope="scope"
              >
                <form-component
                  v-model="scope.row"
                  :view-flag="tableColumnSlot.viewFlag"
                  :form-item="tableColumnSlot.methodId"
                />
              </template>
              <template
                slot="tableAlias"
                slot-scope="scope"
              >
                <form-component
                  v-model="scope.row"
                  :view-flag="tableColumnSlot.viewFlag"
                  :form-item="tableColumnSlot.tableAlias"
                />
              </template>
              <template
                slot="value"
                slot-scope="scope"
              >
                <form-component
                  v-model="scope.row"
                  :view-flag="tableColumnSlot.viewFlag"
                  :form-item="tableColumnSlot.value"
                />
              </template>
              <template
                slot="oper"
                slot-scope="scope"
              >
                <form-component
                  v-model="scope.row"
                  :view-flag="tableColumnSlot.viewFlag"
                  :form-item="tableColumnSlot.oper"
                />
              </template>
              <template
                slot="valueType"
                slot-scope="scope"
              >
                <form-component
                  v-model="scope.row"
                  :view-flag="tableColumnSlot.viewFlag"
                  :form-item="tableColumnSlot.valueType"
                />
              </template>
              <template
                slot="status"
                slot-scope="scope"
              >
                <form-component
                  v-model="scope.row"
                  :view-flag="tableColumnSlot.viewFlag"
                  :form-item="tableColumnSlot.status"
                />
              </template>
              <template
                slot="operation"
                slot-scope="scope"
              >
                <el-button
                  circle
                  icon="el-icon-delete"
                  :title="$t('operation.delete')"
                  @click="handleDatascopeDelClick(scope.$index)"
                />
              </template>
            </simple-table>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div slot="footer">
      <el-button @click="handleDialogClose">
        {{ viewFlag ? $t('operation.close') : $t('operation.cancel') }}
      </el-button>
      <el-button
        v-if="!viewFlag"
        type="primary"
        @click="handleDataSubmit"
      >
        {{ $t('operation.submit') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import Axios from 'axios'
const BASEURL = {
  get: '/system/role/get',
  save: '/system/role/save',
  update: '/system/role/update',
  // 菜单树形结构
  menu: '/system/menu/treeData'
}
export default {
  name: 'SystemRoleDetail',
  props: {
    showFlag: { type: Boolean, default: false },
    viewFlag: { type: Boolean },
    detailData: { type: Object }
  },
  data () {
    return {
      tabValue: 'first',
      formModel: { status: 'Y' },
      menuTreeData: [],
      tableData: [],
      containerHeight: '0px',
      checkedKeys: []
    }
  },
  inject: ['handleDataSearch'],
  computed: {
    currentShowFlag: {
      get () { return this.showFlag },
      set (val) { this.$emit('update:showFlag', val) }
    },

    status () { return this.$getDictList('data_status') },

    formField () {
      return [
        {
          prop: 'name',
          label: this.$t('system_role.name'),
          type: 'Input',
          rules: { trigger: 'blur', message: this.$t('validate.notBlank'), required: true }
        },
        {
          prop: 'status',
          label: this.$t('system_role.status'),
          type: 'RadioGroup',
          component: { optionList: this.status }
        },
        {
          prop: 'remarks',
          label: this.$t('system_role.remarks'),
          type: 'Input',
          component: { type: 'textarea' },
          col: { xs: 24, sm: 24, md: 24 }
        }
      ]
    },

    tableColumn () {
      const baseTableColumn = [
        { prop: 'ruleKey', label: this.$t('system_role.ruleKey'), minWidth: 160, slotName: 'controllerId' },
        // { prop: 'method.id', label: this.$t('system_role.method'), width: 250, slotName: 'methodId' },
        { prop: 'tableName', label: this.$t('system_role.tableName'), minWidth: 160, slotName: 'tableId' },
        { prop: 'tableAlias', label: this.$t('system_role.tableAlias'), minWidth: 160, slotName: 'tableAlias' },
        { prop: 'attrInUser', label: this.$t('system_role.attrInUser'), minWidth: 160, slotName: 'attrInUser' },
        { prop: 'columnName', label: this.$t('system_role.columnName'), minWidth: 160, slotName: 'columnId' },
        // { prop: 'valueType', label: this.$t('system_role.valueType'), width: 150, slotName: 'valueType' },
        { prop: 'operation', label: this.$t('system_role.operation'), minWidth: 160, slotName: 'oper' },
        { prop: 'columnValue', label: this.$t('system_role.columnValue'), minWidth: 160, slotName: 'columnValue' },
        { prop: 'role', label: this.$t('system_role.status'), minWidth: 160, slotName: 'role' }
      ]
      if (this.viewFlag) return baseTableColumn
      else {
        return baseTableColumn.concat([{ label: this.$t('field.operation'), slotName: 'operation', width: 60, fixed: 'right' }])
      }
    },

    tableColumnSlot () {
      return {
        viewFlag: this.viewFlag,
        controllerId: {
          prop: 'controllerId',
          type: 'Input'
        },
        methodId: {
          prop: 'methodId',
          type: 'Input'
        },
        columnId: {
          prop: 'columnId',
          type: 'Input'
        },
        tableId: {
          prop: 'tableId',
          type: 'Input'
        },
        tableAlias: {
          prop: 'tableAlias',
          type: 'Input'
        },
        attrInUser: {
          prop: 'attrInUser',
          type: 'Input'
        },
        value: {
          prop: 'value',
          type: 'Input'
        },
        oper: {
          prop: 'operation',
          type: 'Input'
        },
        valueType: {
          prop: 'valueType',
          type: 'Input'
        },
        status: {
          prop: 'status',
          type: 'Input'
        }
      }
    }
  },
  methods: {
    handleDialogClose () {
      this.currentShowFlag = false
    },
    menuTranslate (data) {
      return this.$t(data.title)
    },
    handleDataSubmit () {
      this.$refs.form.$refs.simpleForm.validate(status => {
        if (status) {
          const loadingFlag = this.$loading({ target: this.$el.querySelector('.el-dialog') })
          const submitModel = this.$_.cloneDeep(this.formModel)

          const checkedNodes = this.$refs.permissionTree.getCheckedNodes()
          const halfCheckedNodes = this.$refs.permissionTree.getHalfCheckedNodes()
          const permissionNodes = checkedNodes.concat(halfCheckedNodes, { id: 'root_id' })
          submitModel.menuList = permissionNodes

          submitModel.sysDataPermissionList = this.tableData.map(item => {
            item.controller = { id: item.controllerId }
            item.method = { id: item.methodId }
            item.column = { id: item.columnId }
            item.table = { id: item.tableId }
            return item
          })

          this.$axios
            .post(this.detailData.id ? BASEURL.update : BASEURL.save, submitModel)
            .then(resp => {
              this.currentShowFlag = false
              this.$message({ type: 'success', message: this.$t('tip.saveSuccess') })
              this.handleDataSearch()
            })
            .finally(() => {
              loadingFlag.close()
            })
        }
      })
    },
    handleDialogOpen () {
      this.$nextTick(() => {
        this.calTableHeight()
      })

      if (!this.detailData.id) {
        const loadingFlag = this.$loading({ target: this.$el.querySelector('.el-dialog') })
        this.$axios
          .post(BASEURL.menu, {})
          .then(resp => {
            const respData = resp.data
            this.menuTreeData = respData
          })
          .finally(() => {
            loadingFlag.close()
          })

        return
      }

      const requestList = [this.$axios.post(BASEURL.menu, {}), this.$axios.post(BASEURL.get, { id: this.detailData.id })]
      const loadingFlag = this.$loading({ target: this.$el.querySelector('.el-dialog') })
      Axios
        .all(requestList)
        .then(Axios.spread((menuResp, resp) => {
          const respData = resp.data
          this.formModel = respData

          const menuRespData = menuResp.data
          this.menuTreeData = menuRespData

          this.tableData = respData.sysDataPermissionList.map(item => {
            // item.controllerId = item.controller.id
            // item.methodId = item.method.id
            // item.tableId = item.table.id
            // item.columnId = item.column.id
            return item
          })

          this.checkedKeys.splice(0)
          this.menuTreeData.forEach(menuItem => {
            this.getCheckedKeys(menuItem)
          })

          this.$refs.permissionTree.setCheckedKeys(this.checkedKeys)
        }))
        .finally(() => {
          loadingFlag.close()
        })
    },
    getCheckedKeys (menuItem) {
      if (this.formModel.menuList && this.formModel.menuList.map(menuListItem => menuListItem.id).indexOf(menuItem.id) >= 0) {
        if (menuItem.children && menuItem.children.length > 0) {

        } else {
          this.checkedKeys.push(menuItem.id)
        }
      }

      if (menuItem.children && menuItem.children.length > 0) {
        menuItem.children.forEach((item) => {
          this.getCheckedKeys(item)
        })
      }
    },
    calTableHeight () {
      const mainHeight = this.$el.querySelector('.el-dialog__body').offsetHeight - 20
      const formHeight = this.$el.querySelector('.el-form').offsetHeight
      // dialog内容总高度 - 表单高度 - tabbar高度
      this.containerHeight = mainHeight - formHeight - 55 + 'px'
    },
    // 数据权限新增一行
    handleDatascopeAddClick () {
      this.tableData.push({})
    },
    handleDatascopeDelClick (idx) {
      this.tableData.splice(idx, 1)
    },
    handleDialogClosed () {
      this.$refs.form.resetFields()
      this.menuTreeData.splice(0)
      this.tabValue = 'first'
      this.$refs.permissionTree.setCheckedKeys([])
      this.tableData.splice(0)
      this.formModel = { status: 'Y' }
    }
  }
}
</script>
